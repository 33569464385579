import React,{ useEffect, useState } from 'react';
import querys from '../services/index'
import Stars from '../components/stars'
import Modal from '../components/modal'
import moment from 'moment'
import 'moment/locale/es';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ModalReviews from '../components/modalReviews'
moment.locale('es');


const Reviews = () => {
  const [data, setData] = useState([])
  const [firstElement, setFirstElement] = useState(0)
  const [lastElement, setLastElement] = useState(4)
  const [modalOpen, setmodalOpen] = useState(false)
  const [elemet, setElement] = useState({})
  const [dataSearch, setDataSearch] = useState({})
  const [totalPag, setTotalPag] = useState(0)
  const [pag, setPag] = useState(1)

  const [conductores, setConductores] = useState([])
  const [conductorSearch, setconductorSearch] = useState('')
  const [resultConductores, setResultConductores] = useState([])

  const [autos, setAutos] = useState([])
  const [autosSearch, setAutosSearch] = useState('')
  const [resultAutos, setResultAutos] = useState([])

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;


  useEffect(() => {

    firstPage()
    getConductores()
    getAutos()
  }, [])

  const firstPage = () => {
    setPag(1)
    setFirstElement(1)
    setLastElement(1+4)
    querys.getReviews(null,null,1)
    .then(response => {
      setData(response.data)
      setTotalPag(response.npage)
    })
    .catch( error => {
      console.log(error)
    })
  }

  const onChange = (dates) => {
    const [start, end] = dates;
    const fechaInicio =  moment(start).format('YYYY-MM-DD');
    const fechaFin =  moment(start).format('YYYY-MM-DD');    
    const objectFechas = { fechaInicio, fechaFin }
    document.getElementById('fecha').style.display = 'block'
    setDataSearch({...dataSearch, 'fecha': objectFechas})
  };

  const getConductores = () => {
    querys.getAllDrivers()
    .then(response => {
      setConductores(response)
    })
    .catch( error => {
      console.log(error)
    })
  }

  const getAutos = () => {
    querys.getAutos()
    .then(response => {
      setAutos(response)
    })
    .catch( error => {
      console.log(error)
    })
  }

  const nextPage = () => {
    if (pag < totalPag) {
      setPag(pag+1)
      setFirstElement(lastElement)
      setLastElement(lastElement+4)
      if (Object.entries(dataSearch).length === 0) {
        querys.getReviews(null,null,pag+1)
        .then(response => {
          setData(response.data)
        })
        .catch( error => {
          console.log(error)
        })
      } else {
        querys.searchReview(dataSearch,null,pag+1)
        .then(response => {
          setData(response.data)
        })
        .catch(error => {
          console.log(error)
        })
      }
     }
  }

  const lastPage = () => {
    if (pag < totalPag) {
      setPag(totalPag)
      setFirstElement(totalPag)
      setLastElement(totalPag+4)
      if (Object.entries(dataSearch).length === 0) {
        querys.getReviews(null,null,totalPag)
        .then(response => {
          setData(response.data)
        })
        .catch( error => {
          console.log(error)
        })
      } else {
        querys.searchReview(dataSearch,null,totalPag)
        .then(response => {
          setData(response.data)
        })
        .catch(error => {
          console.log(error)
        })
      }
     }
  }

  const prevPage = () => {
    setPag(pag-1)
    setFirstElement(firstElement-4)
    setLastElement(lastElement-4)
    if (Object.entries(dataSearch).length === 0) { 
    querys.getReviews(null,null,pag-1)
      .then(response => {
        setData(response.data)
      })
      .catch( error => {
        console.log(error)
      })
    } else {
      querys.searchReview(dataSearch,null,pag-1)
      .then(response => {
        setData(response.data)
      })
      .catch(error => {
        console.log(error)
      })
    }
  }
  
  const handleModal = (review) => {
    setmodalOpen(true)
    setElement(review)
  }

  const handleDataForm = (value, name) => {
    document.getElementById(name).style.display = 'block'
    setDataSearch({...dataSearch, [name]: value})
  }

  const handleSearch = (e) => {
    setconductorSearch(e.target.value)
    filterSearch(e.target.value)
  }

  const filterSearch = (param) => {
    const resultadoSearch = conductores.filter(conductor => {
      if (conductor.nombre.toString().toLowerCase().includes(param.toLowerCase()) || conductor.apellido.toString().toLowerCase().includes(param.toLowerCase())) {
        return conductor
      }
    });
    setResultConductores(param !== '' ? resultadoSearch : [])
  }

  const selectConductor = (conductor, name) => {
    document.getElementById(name).style.display = 'block'
    setconductorSearch(conductor.nombre + ' ' + conductor.apellido)
    setDataSearch({...dataSearch, iddriver: conductor.id})
    setResultConductores([])
  }

  const handleSearchAuto = (e) => {
    setAutosSearch(e.target.value)
    filterSearchAuto(e.target.value)
  }

  const filterSearchAuto = (param) => {
    const resultadoSearch = autos.filter(auto => {
      if (auto.id?.toString().toLowerCase().includes(param.toLowerCase()) || auto.matricula?.toString().toLowerCase().includes(param.toLowerCase()) || auto.modelo?.toString().toLowerCase().includes(param.toLowerCase())) {
        return auto
      }
    });
    setResultAutos(param !== '' ? resultadoSearch : [])
  }

  const selectAuto = (auto, name) => {
    document.getElementById(name).style.display = 'block'
    setAutosSearch(auto.id + '-' + auto.matricula + '-' + auto.modelo)
    setDataSearch({...dataSearch, idcar: auto.id})
    setResultAutos([])
  }

  const sendSearch = (e) => {
    e.preventDefault()
    setPag(1)
    if (Object.entries(dataSearch).length === 0) {
      querys.getReviews(null,null,1)
      .then(response => {
        setData(response.data)
        setTotalPag(response.npage)
      })
      .catch( error => {
        console.log(error)
      })
    } else {
      querys.searchReview(dataSearch,null,1)
      .then(response => {
        setData(response.data)
        setTotalPag(response.npage)
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

  const deletProperity = (name, funcion) => {
    if (name ==='fecha') {
      setDateRange([null,null])
    }
    delete dataSearch[name]
    const input = document.querySelector(`.${name}`)
    input.value = ''
    if (funcion) {
      funcion('')
    }
    document.getElementById(name).style.display = 'none'
  } 

  const deletRate = () => {
    delete dataSearch.rate
    const radios = document.querySelectorAll('.radio')
    for (let index = 0; index < radios.length; index++) {
      radios[index].checked = false;
  }
  document.getElementById('rate').style.display = 'none'
  }

  const numberForLeft = (number) => {
    const string = number.toString()
    const totalCeros = 5 - string.length

    return string.padStart(4, '0')
  }

  return (
    <div className='review'>
      <form className='review-buttons' onSubmit={(e) => sendSearch(e)}>
        <div className="review-button">
          <div className="review-buttonTitle"><span>Fecha</span> <div id="fecha" className='review-buttonReset' onClick={ () => deletProperity('fecha')} ><i className="fa-solid fa-x"></i></div></div>
          <div className='review-input'>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              placeholderText="Selecciona una fecha"
              onChange={(update) => {
                const fechaInicio = update[0]  ? moment(update[0]).format('YYYY-MM-DD') : null;
                const fechaFin = update[1] ? moment(update[1]).format('YYYY-MM-DD') : null;    
                const objectFechas = { fechaInicio, fechaFin }
                document.getElementById('fecha').style.display = 'block'
                setDataSearch({...dataSearch, 'fecha': objectFechas})
                setDateRange(update);
              }}
            />
          </div>
        </div>
        <div className="review-button">
          <div className="review-buttonTitle"><span>Conductor</span> <div id="iddriver" className='review-buttonReset' onClick={ () => deletProperity('iddriver', setconductorSearch)} ><i className="fa-solid fa-x"></i></div></div>
          <div className='review-input'><input value={ conductorSearch } className='iddriver' type="text" placeholder='Nombre del conductor' onChange={(e) => handleSearch(e)} /></div>
          <div className='review-selectConductores'>{resultConductores.map(conductor => <div onClick={() => selectConductor(conductor, 'iddriver')}>{conductor.nombre} {conductor.apellido}</div>)}</div>
        </div>
        <div className="review-button">
          <div className="review-buttonTitle"><span>Unidad</span> <div id="idcar" className='review-buttonReset' onClick={ () => deletProperity('idcar', setAutosSearch)} ><i className="fa-solid fa-x"></i></div></div>
          <div className='review-input'><input value={ autosSearch } className='idcar' type="text" placeholder='Numero de unidad' onChange={(e) => handleSearchAuto(e)} /></div>
          <div className='review-selectConductores'>{resultAutos.map(autos => <div onClick={() => selectAuto(autos, 'idcar')}>{autos.id} - {autos.matricula} - {autos.modelo}</div>)}</div>
        </div>
        <div className="review-button">
          <div className="review-buttonTitle"><span>Calificación</span> <div id="rate" className='review-buttonReset' onClick={ () => deletRate() } ><i className="fa-solid fa-x"></i></div></div>
          <div className="startWidget">
            <input type="radio" className='radio' name="rate" id="rate-5" onChange={() => handleDataForm(5, 'rate')}/>
            <label htmlFor="rate-5" className='fas fa-star'></label>
            <input type="radio" className='radio' name="rate" id="rate-4" onChange={() => handleDataForm(4, 'rate')}/>
            <label htmlFor="rate-4" className='fas fa-star'></label>
            <input type="radio" className='radio' name="rate" id="rate-3" onChange={() => handleDataForm(3, 'rate')}/>
            <label htmlFor="rate-3" className='fas fa-star'></label>
            <input type="radio" className='radio' name="rate" id="rate-2" onChange={() => handleDataForm(2, 'rate')}/>
            <label htmlFor="rate-2" className='fas fa-star'></label>
            <input type="radio" className='radio' name="rate" id="rate-1" onChange={() => handleDataForm(1, 'rate')}/>
            <label htmlFor="rate-1" className='fas fa-star'></label>
          </div>
        </div>
        <button className="review-button">
          <div className="review-buttonTitle" type='submit'><span>Buscar</span> <i className="fa-solid fa-magnifying-glass"></i></div>
        </button>
      </form>
      <div className='review-bodyDrivers'>
        <div className="review-bodyTableHead">
          <div>Nombre</div>
          <div>Conductor</div>
          <div>Unidad asignada</div>
          <div>Calificación</div> 
          <div>Comentario</div>
        </div>
        <div className="review-bodyTable">
        {data.length > 0 ? data.map(item => {
          return (
            <div className='review-bodyTableItem' onClick={() => handleModal(item)}>
              <div>{ item.name }</div>
              <div>{ item.nombre + ' ' + item.apellido }</div>
              <div>{ numberForLeft(item.idcar) }</div>
              <div><Stars rate={ item.rate } /></div>
              <div>{ item.review }</div>
            </div>
          )
        }) : <div>Sin resultados</div>
        }
        </div>
      </div>
      <div className='pag'>
        <div>
          <button onClick={() =>firstPage()}><i className="fa-solid fa-chevrons-left"></i></button>
          { pag !== 1 && <button onClick={() =>prevPage()}><i className="fa-solid fa-arrow-left"></i></button> }
          Pag.{pag} 
          {pag < totalPag && <button onClick={() =>nextPage()}><i className="fa-solid fa-arrow-right"></i></button>}
          <button onClick={() =>lastPage()}><i className="fa-solid fa-chevrons-right"></i></button>
        </div>
      </div>
      {
        modalOpen && 
        <Modal close={setmodalOpen}> 
          <ModalReviews elemet={elemet} />
        </Modal> 
      } 
    </div>
  );
};

export default Reviews;