import React, { useState, useEffect} from 'react';
import Aside from './aside'
import { Outlet } from 'react-router-dom'
import { useAuthContexto } from '../contexts/authContext'
import { Navigate } from "react-router-dom";
import querys from '../services/index'
import moment from 'moment'
import Modal from '../components/modal'
import ModalReviews from '../components/modalReviews'
  
const Dashboard = () => {
  const { isAuthenticated } = useAuthContexto()
  const [active, setActive] = useState(false)
  const [data, setData] = useState([])
  const [numberNotification, setNumberNotification] = useState(0)
  const [modalOpen, setmodalOpen] = useState(false)
  const [elemet, setElemet] = useState({})

  useEffect(() => {
    getNotification()
  }, [])

  const getNotification = () => {
    querys.getNotification(null, null)
    .then(response => {
      setData(response)
      const filterNumber = response.filter(item => item.visto === 'false')
      setNumberNotification(filterNumber.length)
    })
    .catch( error => {
      console.log(error)
    })
  }

  const getReview = (id, idalerta) => {
    const body = { id:id, idalerta: idalerta }
    querys.getReview(body, null)
    .then(response => {
      setElemet(response);
      setmodalOpen(true)
      setActive(false)
      getNotification()
    })
    .catch( error => {
      console.log(error)
    })
  }

  if (isAuthenticated) {
    return (
      <div className='dashboard'>
        <Aside/>
        <div className='component'>
          <div className="component-wrapper">
            <div className='component-notification'>
              <div className="component-notification-icon"  onClick={() => setActive(!active)}>
                <i className="fa-regular fa-bell"></i>
                { numberNotification > 0 && <div className="component-notification-alertIcon">
                  { numberNotification }
                </div> }
              </div>
              <div className={`component-notification-container ${ !active && 'activeNotification' }`}>
                {
                  data.map(item => {
                    return(
                      <div className='component-activeNotification' onClick={() => getReview(item.idreview, item.id)}>
                        <span>
                          <label className='component-notification-date'>{moment(item.fecha).format('lll') }</label>
                          {`${item.nombre} obtuvo una reseña de ${item.rate} estrellas `}
                        </span>
                        { item.visto === 'false' && <div className='notificationActiive'></div> }
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <Outlet/>
          </div>
        </div>
        {
        modalOpen && 
        <Modal close={setmodalOpen}> 
          <ModalReviews elemet={elemet} />
        </Modal> 
      } 
      </div>
    );
  } else {
    return( 
      <Navigate replace to="/login" />
    )
  }

};

export default Dashboard;