import axios from "axios";

const baseUrlApi = "https://apittpv.regatelecom.mx";

const querys = {
  async insertReview (body, dispatch) { 
    return await apiAction(
      baseUrlApi+'/reviews/addnew',
      dispatch,
      body,
      null,
      null,
      null,
      null,
      null,
      'POST'
    )
  },
  async getReviews (body, dispatch, pag) { 
    return await apiAction(
      baseUrlApi+'/reviews/'+pag,
      dispatch,
      body,
      null,
      null,
      null,
      null,
      null,
      'GET'
    )
  },
  async getDrivers (body, dispatch,pag) { 
    return await apiAction(
      baseUrlApi+'/drivers/' + pag,
      dispatch,
      body,
      null,
      null,
      null,
      null,
      null,
      'GET'
    )
  },
  async getAllDrivers (body, dispatch) { 
    return await apiAction(
      baseUrlApi+'/drivers/',
      dispatch,
      body,
      null,
      null,
      null,
      null,
      null,
      'GET'
    )
  },
  async getAllDriversSearch (body, dispatch) { 
    return await apiAction(
      baseUrlApi+'/drivers/search',
      dispatch,
      body,
      null,
      null,
      null,
      null,
      null,
      'POST'
    )
  },
  async getAutos (body, dispatch) { 
    return await apiAction(
      baseUrlApi+'/autos',
      dispatch,
      body,
      null,
      null,
      null,
      null,
      null,
      'GET'
    )
  },
  async getAutosSearch (body, dispatch) { 
    return await apiAction(
      baseUrlApi+'/autos/search',
      dispatch,
      body,
      null,
      null,
      null,
      null,
      null,
      'POST'
    )
  },
  async getAutosPag (body, dispatch,pag) { 
    return await apiAction(
      baseUrlApi+'/autos/'+pag,
      dispatch,
      body,
      null,
      null,
      null,
      null,
      null,
      'GET'
    )
  },
  async getUserPag (body, dispatch,pag) { 
    return await apiAction(
      baseUrlApi+'/user/'+pag,
      dispatch,
      body,
      null,
      null,
      null,
      null,
      null,
      'GET'
    )
  },
  async getAutosAvailable (body, dispatch) { 
    return await apiAction(
      baseUrlApi+'/autos/available',
      dispatch,
      body,
      null,
      null,
      null,
      null,
      null,
      'POST'
    )
  },
  async insertDriver (body, dispatch) { 
    return await apiAction(
      baseUrlApi+'/drivers/addnew',
      dispatch,
      body,
      null,
      null,
      null,
      null,
      { "Content-Type": "multipart/form-data" },
      'POST'
    )
  },
  async editDriver (body, dispatch) { 
    return await apiAction(
      baseUrlApi+'/drivers/edit',
      dispatch,
      body,
      null,
      null,
      null,
      null,
      { "Content-Type": "multipart/form-data" },
      'POST'
    )
  },
  async insertAuto (body, dispatch) { 
    return await apiAction(
      baseUrlApi+'/autos/addnew',
      dispatch,
      body,
      null,
      null,
      null,
      null,
      null,
      'POST'
    )
  },
  async insertUser (body, dispatch) { 
    return await apiAction(
      baseUrlApi+'/user/create-user',
      dispatch,
      body,
      null,
      null,
      null,
      null,
      null,
      'POST'
    )
  },
  async updatetUser (body, dispatch) { 
    return await apiAction(
      baseUrlApi+'/user/edit-user',
      dispatch,
      body,
      null,
      null,
      null,
      null,
      null,
      'POST'
    )
  },
  async editAuto (body, dispatch) { 
    return await apiAction(
      baseUrlApi+'/autos/edit',
      dispatch,
      body,
      null,
      null,
      null,
      null,
      null,
      'POST'
    )
  },
  async searchReview (body, dispatch,pag) { 
    return await apiAction(
      baseUrlApi+'/reviews/search/' + pag,
      dispatch,
      body,
      null,
      null,
      null,
      null,
      null,
      'POST'
    )
  },
  async login (body, dispatch) { 
    return await apiAction(
      baseUrlApi+'/user/sigin',
      dispatch,
      body,
      null,
      null,
      null,
      null,
      null,
      'POST'
    )
  },
  async reportAll (body, dispatch) { 
    return await apiAction(
      baseUrlApi+'/drivers/reportAll',
      dispatch,
      body,
      null,
      null,
      null,
      null,
      null,
      'POST'
    )
  },
  async reportUser (body, dispatch) { 
    return await apiAction(
      baseUrlApi+'/drivers/reportuser',
      dispatch,
      body,
      null,
      null,
      null,
      null,
      null,
      'POST'
    )
  },
  async reportAllUser (body, dispatch) { 
    return await apiAction(
      baseUrlApi+'/drivers/reportalluser',
      dispatch,
      body,
      null,
      null,
      null,
      null,
      null,
      'POST'
    )
  },
  async deleteUser (body, dispatch) { 
    return await apiAction(
      baseUrlApi+'/drivers/deleteuser',
      dispatch,
      body,
      null,
      null,
      null,
      null,
      null,
      'POST'
    )
  },
  async getNotification (body, dispatch) { 
    return await apiAction(
      baseUrlApi+'/reviews/getnotification',
      dispatch,
      body,
      null,
      null,
      null,
      null,
      null,
      'POST'
    )
  },
  async getReview (body, dispatch) { 
    return await apiAction(
      baseUrlApi+'/reviews/getreview',
      dispatch,
      body,
      null,
      null,
      null,
      null,
      null,
      'POST'
    )
  },
}


const apiAction = async (url,dispatch,body,modelApi,action,actionError,actionLoading,headers,method) => {
  // dispatch(actionLoading())
  const options = {
    method,
    headers,
    url,
    data:body
  }
  const result = await axios(options)
  .then(function(response){
    let data = false;
    if (modelApi) {
      data = new modelApi(response.data) // ejecutamos la funcion de modelo plural mandandole la data
      dispatch(action(data))
    }
    return data ? data : response.data
  }).catch(function(error){
    console.log(error);
    // dispatch(actionError(error.response?.data))
    return Promise.reject(error.message)
  });
  return result
}

export default querys;