import React from 'react';

const Modal = ({close,children}) => {
  return (
    <div className='modal'>
      <div className="modal-container">
        <div className="modal-header"><span onClick={() => close(false)}><i className="fa-solid fa-x"></i></span></div>
        {children}
      </div>
    </div>
  );
};

export default Modal;