const traslatesCopys = [
  {
    "CopyId": "FORM_YOUR_NAME",
    "text": "¿Cual es tu nombre?"
  },
  {
    "CopyId": "FORM_YOUR_COUNTRY",
    "text": "País de residencia"
  },
  {
    "CopyId": "FORM_YOUR_EMAIL",
    "text": "Correo electrónico"
  },
  {
    "CopyId": "FORM_YOUR_EXPERIENCE",
    "text": "Califica tu experiencia"
  },
  {
    "CopyId": "FORM_YOUR_LEAVE",
    "text": "Dejar un comentario"
  },
  {
    "CopyId": "FORM_YOUR_TITLE",
    "text": "Gracias por viajar con nosotros"
  },
  {
    "CopyId": "FORM_YOUR_BUTTON",
    "text": "Enviar reseña"
  },
  {
    "CopyId": "FORM_YOUR_TOOLTIP",
    "text": "Califica nuestro servicio"
  },
  {
    "CopyId": "FORM_YOUR_PLACEHOLDER_NAME",
    "text": "¿Quién viaja con nosotros?"
  },
  {
    "CopyId": "FORM_YOUR_PLACEHOLDER_COUNTRY",
    "text": "Busca tu pais"
  },
  {
    "CopyId": "FORM_YOUR_PLACEHOLDER_EMAIL",
    "text": "Cual es tu correo electronico"
  },
  {
    "CopyId": "FORM_YOUR_PLACEHOLDER_REVIEW",
    "text": "Cuéntanos tu experiencia con nuestro servicio"
  },
  {
    "CopyId": "FORM_YOUR_PLACEHOLDER_TANKS",
    "text": "Gracias por su comentario para mejorar nuestro servicio."
  },
]


export default traslatesCopys;