import { createContext, useCallback, useContext, useMemo, useState } from "react";
import PropTypes from 'prop-types'

export const AuthContext = createContext();

const MY_AUTH_APP = 'MY_AUTH_APP'
const MY_AUTH_APP_NAME = 'MY_AUTH_APP_NAME'

export function AuthContextProvider({children}) {
  const [isAuthenticated, setIsAuthenticated] = useState(window.localStorage.getItem(MY_AUTH_APP) ?? false)
  const [name, setName] = useState(window.localStorage.getItem(MY_AUTH_APP_NAME) ?? '')

  const login = useCallback(function (nombre) {
    window.localStorage.setItem(MY_AUTH_APP,true)
    window.localStorage.setItem(MY_AUTH_APP_NAME,nombre)
    setName(nombre)
    setIsAuthenticated(true);
  },[]);

  const logout = useCallback(function () {
    window.localStorage.setItem(MY_AUTH_APP, false)
    setIsAuthenticated(false);
  },[]);

  const value = useMemo(
    () => ({
      login,
      logout,
      isAuthenticated,
      name
    }),
    [login, logout, isAuthenticated, name]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

AuthContext.Provider.propTypes = {
  children: PropTypes.object
}

export function useAuthContexto() {
  return useContext(AuthContext)
}