import React from 'react';
import { NavLink } from 'react-router-dom';
import { useAuthContexto } from '../contexts/authContext'

const Aside = () => {
  const { logout, name } = useAuthContexto();

  return (
    <div className='slide'>
      <div className='slide-perfil'>
        <div className='slide-perfilImagen'>
          <i className="fa-regular fa-user"></i>
        </div>
        <div className='slide-perfilName'>{ name }</div>
      </div>
      <div className='slide-menu'>
        <NavLink to='/dashboard' className={({isActive}) => ['slide-menuElement', isActive ? 'activo' : null].join(" ")}>
          <i className="fa-sharp fa-regular fa-medal"></i> Reseña
        </NavLink>
        <NavLink  to='/dashboard/conductores' className={({isActive}) => ['slide-menuElement', isActive ? 'activo' : null].join(" ")}>
          <i className="fa-regular fa-id-card"></i> Conductores
        </NavLink>
        <NavLink to='/dashboard/autos' className={({isActive}) => ['slide-menuElement', isActive ? 'activo' : null].join(" ")}>
          <i className="fa-regular fa-cars"></i> Autos
        </NavLink>
        <NavLink to='/dashboard/usuarios' className={({isActive}) => ['slide-menuElement', isActive ? 'activo' : null].join(" ")}>
          <i className="fa-regular fa-user"></i> Usuarios
        </NavLink>
        <button className='slide-menuElement' onClick={() => logout()}>
          <i className="fa-duotone fa-arrow-right-from-bracket"></i> Salir
        </button>
      </div>
    </div>
  );
};

export default Aside;