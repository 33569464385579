import React,{ useEffect, useState, useRef } from 'react';
import querys from '../services/index'
import Modal from '../components/modal'
import { QRCodeSVG } from 'qrcode.react';
import html2canvas from "html2canvas";
import icon from '../assetns/icon-trip-advisor.png'
import logo from '../assetns/logo.png'

const Autos = () => {
  const [data, setData] = useState([])
  const [firstElement, setFirstElement] = useState(0)
  const [lastElement, setLastElement] = useState(4)
  const [bodyForm, setBodyForm] = useState({})
  const [modalOpen, setmodalOpen] = useState(false)
  const [modalOpenEdit, setmodalOpenEdit] = useState(false)
  const [bodyFormEdit, setBodyFormEdit] = useState({})
  const [srcQR, setSrcQR] = useState('')
  const [pag, setPag] = useState(1)
  const exportRef = useRef();
  const [totalPag, setTotalPag] = useState(0)

  const [autos, setAutos] = useState([])
  const [autosSearch, setAutosSearch] = useState('')
  const [resultAutos, setResultAutos] = useState([])
  const [dataSearch, setDataSearch] = useState({})

  useEffect(() => {
    getAutos(null, null, 1)
    getAutosAll()
  }, [])

  const getAutos = (form, body, pag) => {
    querys.getAutosPag(form, body, pag)
    .then(response => {
      setData(response.data)
      setTotalPag(response.npage)
    })
    .catch( error => {
      console.log(error)
    })
  }

  const getAutosAll = () => {
    querys.getAutos()
    .then(response => {
      setAutos(response)
    })
    .catch( error => {
      console.log(error)
    })
  }

  const nextPage = () => {
    if (pag < totalPag) {
      setPag(pag+1)
      setFirstElement(lastElement)
      setLastElement(lastElement+4)
      getAutos(null, null, pag+1)
    }
  }
  const prevPage = () => {
    setPag(pag-1)
    setFirstElement(firstElement-4)
    setLastElement(lastElement-4)
    getAutos(null, null, pag-1)
  }

  const submitForm = (e) => {
    e.preventDefault();
    const inputs =  document.querySelectorAll('newCar')
    querys.insertAuto(bodyForm)
    .then(response => {
      setBodyForm({})
      getAutos(null, null, 1)
      inputs.forEach(input => input.value = '')
      setmodalOpen(false)
    })
    .catch(error => {
      console.log(error)
    })
  }

  const submitFormEdit = (e) => {
    e.preventDefault();
    querys.editAuto(bodyFormEdit)
    .then(response => {
      getAutos(null, null, 1)
      setmodalOpenEdit(false)
    })
    .catch(error => {
      console.log(error)
    })
  }
  
  const handleQR = (item) => {
    setSrcQR(`${window.location.origin}/en/${item.id}`)
    setmodalOpenEdit(true)
    setBodyFormEdit(item)
  }

  const closeModal = () => {
    setmodalOpenEdit(false)
  }

  const numberForLeft = (number) => {
    if (number === 'null') {
      return 'Sin Auto'
    }
    const string = number.toString()
    const totalCeros = 5 - string.length
    console.log(string, totalCeros, 'total')

    return string.padStart(4, '0')
  }

  const exportAsImage = async (imageFileName) => {
    const test = document.getElementById('codigoQR')
    const canvas = await html2canvas(test);
    const image = canvas.toDataURL("image/png", 1.0);
    downloadImage(image, imageFileName);
  }
  const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;
    
    fakeLink.href = blob;
    
    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);
    
    fakeLink.remove();
  };

  const selectAuto = (auto, name) => {
    document.getElementById(name).style.display = 'block'
    setAutosSearch(auto.id + '-' + auto.matricula + '-' + auto.modelo)
    setDataSearch({...dataSearch, idcar: auto.id})
    setResultAutos([])
  }

  const deletProperity = (name, funcion) => {
    delete dataSearch[name]
    const input = document.querySelector(`.${name}`)
    input.value = ''
    if (funcion) {
      funcion('')
    }
    document.getElementById(name).style.display = 'none'
  } 

  const handleSearchAuto = (e) => {
    setAutosSearch(e.target.value)
    filterSearchAuto(e.target.value)
  }

  const filterSearchAuto = (param) => {
    const resultadoSearch = autos.filter(auto => {
      if (auto.id?.toString().toLowerCase().includes(param.toLowerCase()) || auto.matricula?.toString().toLowerCase().includes(param.toLowerCase()) || auto.modelo?.toString().toLowerCase().includes(param.toLowerCase())) {
        return auto
      }
    });
    setResultAutos(param !== '' ? resultadoSearch : [])
  }

  const sendSearch = (e) => {
    e.preventDefault()
    setPag(1)
    if (Object.entries(dataSearch).length === 0) {
      getAutos(null, null, 1)
    } else {
      querys.getAutosSearch(dataSearch,null)
      .then(response => {
        setData(response)
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

  return (
    <div className='review'>
      <form className='review-buttons' onSubmit={(e) => sendSearch(e)}>
        <div className="review-button" onClick={() => setmodalOpen(true)}>
          <div className="review-buttonTitle" ><span>Agregar Auto</span></div>
        </div>
        <div className="review-button">
          <div className="review-buttonTitle"><span>Unidad</span> <div id="idcar" className='review-buttonReset' onClick={ () => deletProperity('idcar', setAutosSearch)} ><i className="fa-solid fa-x"></i></div></div>
          <div className='review-input'><input value={ autosSearch } className='idcar' type="text" placeholder='Numero de unidad' onChange={(e) => handleSearchAuto(e)} /></div>
          <div className='review-selectConductores'>{resultAutos.map(autos => <div onClick={() => selectAuto(autos, 'idcar')}>{autos.id} - {autos.matricula} - {autos.modelo}</div>)}</div>
        </div>
        <button className="review-button">
          <div className="review-buttonTitle" type='submit'><span>Buscar</span> <i className="fa-solid fa-magnifying-glass"></i></div>
        </button>
      </form>
      <div className='review-bodyDrivers'>
        <div className="review-bodyTableHead">
          <div>N.º Unidad</div>
          <div>Placas</div>
          <div>Modelo</div>
          <div>Marca</div>
          <div>Año</div>
        </div>
        <div className="review-bodyTable">
        {data.length > 0 && data.map(item => {
          return(
            <div className='review-bodyTableItem' onClick={() => handleQR(item)}>
              <div>{ numberForLeft(item.id) }</div>
              <div>{ item.matricula }</div>
              <div>{ item.modelo }</div>
              <div>{ item.marca }</div>
              <div>{ item.ano }</div>
            </div>
          )
        })}
        </div>
      </div>
      <div className='pag'>
        <div>
          { pag !== 1 && <button onClick={() =>prevPage()}><i className="fa-solid fa-arrow-left"></i></button> }
          Pag.{pag} 
          { pag < totalPag && <button onClick={() =>nextPage()}><i className="fa-solid fa-arrow-right"></i></button> }
        </div>
      </div>
      {modalOpen && <Modal close={setmodalOpen}> 
        <form className='Modal-form-input' onSubmit={e => submitForm(e)}>
          <div className='Modal-form-inputs'>
            <div className='Modal-Input'>
              <label htmlFor="">Matricula</label>
              <input required type="text" value={bodyForm.matricula} onChange={(e) => setBodyForm({...bodyForm, matricula:e.target.value})} className='newCar' />
            </div>
            <div className='Modal-Input'>
              <label htmlFor="">Modelo</label>
              <input required type="text" value={bodyForm.modelo} onChange={(e) => setBodyForm({...bodyForm, modelo:e.target.value})} className='newCar'/>
            </div>
            <div className='Modal-auto'>
              <label htmlFor="">Marca</label>
              <input required type="text" value={bodyForm.marca} onChange={(e) => setBodyForm({...bodyForm, marca:e.target.value})} className='newCar'/>
            </div>
            <div className='Modal-Input'>
              <label htmlFor="">Año</label>
              <input required  type="number" placeholder="YYYY" value={bodyForm.ano} onChange={(e) => setBodyForm({...bodyForm, ano:e.target.value})} className='newCar'/>
            </div>
            <div>
              <button>Guardar</button>
            </div>
          </div>
        </form>
      </Modal> }
      {modalOpenEdit && <Modal close={closeModal}> 
        <form className='Modal-form-input formulario-autos' onSubmit={e => submitFormEdit(e)}>
          <div className='Modal-form-inputs'>
            <div className='Modal-Input'>
              <label htmlFor="">Matricula</label>
              <input required type="text" value={bodyFormEdit.matricula} onChange={(e) => setBodyFormEdit({...bodyFormEdit, matricula:e.target.value})} className='editCar'/>
            </div>
            <div className='Modal-Input'>
              <label htmlFor="">Modelo</label>
              <input required type="text" value={bodyFormEdit.modelo} onChange={(e) => setBodyFormEdit({...bodyFormEdit, modelo:e.target.value})} className='editCar'/>
            </div>
            <div className='Modal-auto'>
              <label htmlFor="">Marca</label>
              <input required type="text" value={bodyFormEdit.marca} onChange={(e) => setBodyFormEdit({...bodyFormEdit, marca:e.target.value})} className='editCar'/>
            </div>
            <div className='Modal-Input'>
              <label htmlFor="">Año</label>
              <input required  type="number" placeholder="YYYY"  value={bodyFormEdit.ano} onChange={(e) => setBodyFormEdit({...bodyFormEdit, ano:e.target.value})} className='editCar'/>
            </div>
            <div>
              <button type='submit'>Guardar</button>
            </div>
            <div>
              <div className='button-formularios' onClick={() => exportAsImage("CodigoQR")}>
                <span>imprimir QR</span>
              </div>
            </div>
          </div>

          <div className='codigoQR' id='codigoQR' ref={ exportRef }>
            <div className='codigoQR-text'>
              <div className='codigoQR-text-logo'>
                <img src={ logo } alt="" />
              </div>
              <div className='codigoQR-text-title'>DID YOU LOVE OUR SERVICE?</div>
              <div className='codigoQR-text-body'>
                <span>Please leave us a review</span>
                <span>It will take 10 seonds and have a big impact on our business</span>
              </div>
              <div className='codigoQR-text-imagen'>
                <img src={ icon } alt="" />
              </div>
            </div>
            <div className='sectionQR'>
              <div>
                <div className='qrImagen'>
                  <QRCodeSVG value={srcQR} />
                </div>
                <div className='textQR'>
                  Scan Me
                </div>
              </div>
              <div className='arrowQR'>
                <i className="fa-solid fa-share-all"></i>
              </div>
            </div>
          </div>
        </form>
      </Modal> }
    </div>
  );
};

export default Autos;