import React,{ useState } from 'react';
import logo from '../assetns/logo.png'
import querys from '../services/index'
import { useAuthContexto } from '../contexts/authContext'
import { useNavigate } from "react-router-dom";


const Login = () =>  {
  const [data, setData] = useState({})
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const { login } = useAuthContexto();
  const navigate = useNavigate()

  const handleSubmit = (e) => {
    e.preventDefault();
    querys.login(data)
    .then(response => {
      if (response.length) {
        setError(false)
        login(`${response[0].nombre} ${response[0].apellido}`)
        setLoading(true)
        setTimeout(() => {
          setLoading(false)
          navigate("/dashboard");
        }, 1000);
      } else {
        setError(true)
      }
    })
    .catch( error => {
      setError(true)
      console.log(error)
    })
  }

  return (
    <form className='login' onSubmit={(e) => handleSubmit(e)}>
      <div className="login-container">
        <div className='login-container-logo'><img src={logo} alt="" /></div>
        <div className='login-container-inputs'>
          <div className="login-container-label"><span>Email</span></div>
          <div className='login-container-input'><input required type="email" onChange={(e) => setData({...data, email:e.target.value })} /></div>
        </div>
        <div className='login-container-inputs'>
          <div className="login-container-label"><span>Contraseña</span></div>
          <div className='login-container-input'><input required type="password" onChange={(e) => setData({...data, password:e.target.value })} /></div>
        </div>
        <div className='login-container-boton'>
          <button disabled={loading} type='sumbit'>{ loading ? 'Cargando...' : 'Iniciar Sesion' }</button>
        </div>
        {error && <div>Error verifica tus credenciales</div>}
      </div>
    </form>
  );
}

export default Login;