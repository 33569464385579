import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';


const persistConfig = {
  key: 'root',
  storage,
}

// const persistedUser =  persistReducer(persistConfig, loginReducer);

export const store = configureStore({
  reducer: {}
})

export const persistor = persistStore(store);