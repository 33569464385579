import React,{ useEffect, useState, useRef } from 'react';
import querys from '../services/index'
import Stars from '../components/stars'
import Modal from '../components/modal'
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { QRCodeSVG } from 'qrcode.react';
import html2canvas from "html2canvas";
import icon from '../assetns/icon-trip-advisor.png'
import logo from '../assetns/logo.png'
import Exporcsv from '../components/exportcsv'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const Conductores = () => {
  const [data, setData] = useState([])
  const [autos, setAutos] = useState([])
  const [autosAvailable, setAutosAvailable] = useState([])
  const [firstElement, setFirstElement] = useState(0)
  const [lastElement, setLastElement] = useState(4)
  const [carselect, setCarselect] = useState('')
  const [bodyForm, setBodyForm] = useState({})
  const [bodyFormEdit, setBodyFormEdit] = useState({})
  const [carselectEdit, setCarselectEdit] = useState('')
  const [modalOpen, setmodalOpen] = useState(false)
  const [modalOpenEdit, setmodalOpenEdit] = useState(false)
  const { idConduct } = useParams();
  const [pag, setPag] = useState(1)
  const [totalPag, setTotalPag] = useState(0)
  const [preview, setPreview] = useState('https://d500.epimg.net/cincodias/imagenes/2016/07/04/lifestyle/1467646262_522853_1467646344_noticia_normal.jpg')
  const [selectedFile, setSelectedFile] = useState()
  const [conductores, setConductores] = useState([])
  const [conductorSearch, setconductorSearch] = useState('')
  const [resultConductores, setResultConductores] = useState([])
  const [dataSearch, setDataSearch] = useState({})
  const [srcQR, setSrcQR] = useState('')
  const exportRef = useRef();
  
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [dateReporteRange,  setDateReporteRange] = useState({})

  const [dataReport, setDataReporte] = useState([]);
  const [DownReporte, setDownReporte ] = useState(false)
  const [modalRepote, setModalReporte ] = useState(false)

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDateUser, endDateUser] = dateRange;

  useEffect(() => {

    getConductores()
    querys.getAutosAvailable()
    .then(response => {
      setAutosAvailable(response)
    })
    .catch( error => {
      console.log(error)
    })

    querys.getAutos()
    .then(response => {
      setAutos(response)
    })
    .catch( error => {
      console.log(error)
    })
    getConductoresAll()
  }, [])

  useEffect(() => {
    if (!selectedFile) {
      setPreview('https://d500.epimg.net/cincodias/imagenes/2016/07/04/lifestyle/1467646262_522853_1467646344_noticia_normal.jpg')
        return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
    },[selectedFile])

  const getConductoresAll = () => {
    querys.getAllDrivers()
    .then(response => {
      setConductores(response)
    })
    .catch( error => {
      console.log(error)
    })
  }

  const getConductores = () => {
    querys.getDrivers(null, null, 1)
    .then(response => {
      setData(response.data)
      setTotalPag(response.npage)
    })
    .catch( error => {
      console.log(error)
    })
  }
  const nextPage = () => {
    if (pag < totalPag) {
      setPag(pag+1)
      setFirstElement(lastElement)
      setLastElement(lastElement+4)
      querys.getDrivers(null, null, pag+1)
      .then(response => {
        setData(response.data)
        setTotalPag(response.npage)
      })
      .catch( error => {
        console.log(error)
      })
    }
  }

  const deletProperity = (name, funcion) => {
    delete dataSearch[name]
    const input = document.querySelector(`.${name}`)
    input.value = ''
    if (funcion) {
      funcion('')
    }
    document.getElementById(name).style.display = 'none'
  } 

  const selectConductor = (conductor, name) => {
    document.getElementById(name).style.display = 'block'
    setconductorSearch(conductor.nombre + ' ' + conductor.apellido)
    setDataSearch({...dataSearch, iddriver: conductor.id})
    console.log(conductor.id)
    setResultConductores([])
  }

  const handleSearchConductor = (e) => {
    setconductorSearch(e.target.value)
    filterSearch(e.target.value)
  }

  const filterSearch = (param) => {
    const resultadoSearch = conductores.filter(conductor => {
      if (conductor.nombre.toString().toLowerCase().includes(param.toLowerCase()) || conductor.apellido.toString().toLowerCase().includes(param.toLowerCase())) {
        return conductor
      }
    });
    setResultConductores(param !== '' ? resultadoSearch : [])
  }

  const prevPage = () => {
    setPag(pag-1)
    setFirstElement(firstElement-4)
    setLastElement(lastElement-4)
    querys.getDrivers(null, null, pag-1)
      .then(response => {
        setData(response.data)
        setTotalPag(response.npage)
      })
      .catch( error => {
        console.log(error)
      })
  }


  const submitForm = (e) => {
    e.preventDefault();
    const inputs =  document.querySelectorAll('input')
    const data = new FormData();
    const object = {...bodyForm,fecha: moment().format('YYYY-MM-DD')}
    for (const property in object) {
      data.append(property, object[property])
    }
    data.append('image',selectedFile)
    querys.insertDriver(data)
    .then(response => {
      setBodyForm({})
      inputs.forEach(input => input.value = '')
      setCarselect('')
      getConductores();
      setmodalOpen(false)
    })
    .catch(error => {
      console.log(error)
    })
  }


  const handleImage = (e) => {
    setSelectedFile(e.target.files[0])
  }

  const closeModal = () => {
    setPreview('https://d500.epimg.net/cincodias/imagenes/2016/07/04/lifestyle/1467646262_522853_1467646344_noticia_normal.jpg');
    setmodalOpen(false);
    setmodalOpenEdit(false)
    setSelectedFile()
  }

  const submitFormEdit = (e) => {
    e.preventDefault();
    const inputs =  document.querySelectorAll('input')
    const data = new FormData();
    const object = {...bodyFormEdit,fecha: moment().format('YYYY-MM-DD'), id:bodyFormEdit.id, alta: moment(bodyFormEdit.alta).format('YYYY-MM-DD')}
    for (const property in object) {
      data.append(property, object[property])
    }
    if (selectedFile) {
      data.append('image',selectedFile) 
    }
    querys.editDriver(data)
    .then(response => {
      setBodyForm({})
      inputs.forEach(input => input.value = '')
      setCarselect('')
      getConductores();
      setmodalOpenEdit(false)
      querys.getAutosAvailable()
      .then(response => {
        setAutosAvailable(response)
      })
      .catch( error => {
        console.log(error)
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  const sendSearch = (e) => {
    e.preventDefault()
    setPag(1)
    if (Object.entries(dataSearch).length === 0) {
      getConductores()
    } else {
      querys.getAllDriversSearch(dataSearch,null)
      .then(response => {
        setData(response)
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

  const exportAsImage = async (imageFileName) => {
    const test = document.getElementById('codigoQR')
    const canvas = await html2canvas(test);
    const image = canvas.toDataURL("image/png", 1.0);
    downloadImage(image, imageFileName);
  }

  const downloadImage = (blob, fileName) => {
    const fakeLink = window.document.createElement("a");
    fakeLink.style = "display:none;";
    fakeLink.download = fileName;
    
    fakeLink.href = blob;
    
    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);
    
    fakeLink.remove();
  };

  const handleQR = (item) => {
    setSrcQR(`${window.location.origin}/en/${item.id}`)
    setmodalOpenEdit(true)
    setBodyFormEdit(item)
  }

  const onChange = (dates) => {
    const [start, end] = dates;
    const fechInicio =  moment(start).format('YYYY-MM-DD');
    const fechFin =  moment(end).format('YYYY-MM-DD');    
    const objectFechas = { fechInicio, fechFin }
    setDateReporteRange(objectFechas);
    setStartDate(start);
    setEndDate(end);
  };

  const handleReport = () => {
    setModalReporte(true)
  }

  const exportReport = () => {
    querys.reportAll({fechInicio: dateReporteRange.fechInicio, fechFin: dateReporteRange.fechFin})
    .then(response => {
      setDataReporte(response);
      setDownReporte(true);
      setTimeout(() => {
        setDownReporte(false);
      }, 1000);
    })
    .catch(error => {
      console.log(error)
    })
  }

  const exportReportUser = () => {
    if (dateReporteRange.fechInicio && dateReporteRange.fechFin) {
      querys.reportUser({fechInicio: dateReporteRange.fechInicio, fechFin: dateReporteRange.fechFin, iddriver: bodyFormEdit.id})
      .then(response => {
        setDataReporte(response);
        setDownReporte(true);
        setTimeout(() => {
          setDownReporte(false);
        }, 1000);
      })
      .catch(error => {
        console.log(error)
      })
    } else {
      querys.reportAllUser({ iddriver: bodyFormEdit.id })
      .then(response => {
        setDataReporte(response);
        setDownReporte(true);
        setTimeout(() => {
          setDownReporte(false);
        }, 1000);
      })
      .catch(error => {
        console.log(error)
      })
    }

  }

  const deleteUser = () => {
    querys.deleteUser({iddriver: bodyFormEdit.id})
    .then(response => {
      setmodalOpenEdit(false)
      window.location.reload()
    })
    .catch(error => {
      console.log(error)
    })
  }

  return (
    <div className='review'>
      <form className='review-buttons' onSubmit={(e) => sendSearch(e)}>
        <div className="review-button" onClick={() => setmodalOpen(true)}>
          <div className="review-buttonTitle" ><span>Agregar Conductor</span></div>
        </div>
        <div className="review-button">
          <div className="review-buttonTitle"><span>Conductor</span> <div id="iddriver" className='review-buttonReset' onClick={ () => deletProperity('iddriver', setconductorSearch)} ><i className="fa-solid fa-x"></i></div></div>
          <div className='review-input'><input value={ conductorSearch } className='iddriver' type="text" placeholder='Nombre del conductor' onChange={(e) => handleSearchConductor(e)} /></div>
          <div className='review-selectConductores'>{resultConductores.map(conductor => <div onClick={() => selectConductor(conductor, 'iddriver')}>{conductor.nombre} {conductor.apellido}</div>)}</div>
        </div>
        <button className="review-button">
          <div className="review-buttonTitle" type='submit'><span>Buscar</span> <i className="fa-solid fa-magnifying-glass"></i></div>
        </button>
        <button className="review-button" onClick={() => handleReport()}>
          Generar Reporte
        </button>
      </form>
      { DownReporte && <Exporcsv data={ dataReport }/> }
      <div className='review-bodyDrivers'>
        <div className="review-bodyTableHead">
          <div>Nombre</div>
          <div>Numero de comentarios</div>
          <div>Calificación promedio</div>
          <div>Estatus</div>
        </div>
        <div className="review-bodyTable">
        {data.length > 0 && data.map(item => {
          return(
            <div className='review-bodyTableItem' onClick={() => handleQR(item)}>
              <div>{ item.nombre } { item.apellido }</div>
              <div>{ item.ncomentarios }</div>
              <Stars rate={ item.promedio } />
              <div>{ item.estatus === 'true' ? <span className='review-habilitado'>Habilitado</span> : <span className='review-inhabilitado'>Inhabilitado</span> }</div>
            </div>
          )
        })}
        </div>
      </div>
      <div className='pag'>
        <div>
          { pag !== 1 && <button onClick={() =>prevPage()}><i className="fa-solid fa-arrow-left"></i></button> }
          Pag.{pag} 
          { pag < totalPag && <button onClick={() =>nextPage()}><i className="fa-solid fa-arrow-right"></i></button>}
        </div>
      </div>
      {modalOpen && <Modal close={closeModal}> 
        <form className='Modal-form-input' onSubmit={e => submitForm(e)}>
          <div className='Modal-form-input-img'>
            <img src={preview} alt="" onClick={() => {document.getElementById('fileNew').click()}} />
            <input className='Modal-form-input-file' accept="image/png, image/jpeg" id='fileNew' type="file" onChange={(e) => handleImage(e)} />
          </div>
          <div className='Modal-form-inputs'>
            <div className='Modal-Input'>
              <label htmlFor="">Nombre</label>
              <input required type="text" value={bodyForm.nombre} onChange={(e) => setBodyForm({...bodyForm, nombre:e.target.value})} />
            </div>
            <div className='Modal-Input'>
              <label htmlFor="">Apellido</label>
              <input required type="text" value={bodyForm.apellido} onChange={(e) => setBodyForm({...bodyForm, apellido:e.target.value})}/>
            </div>
            <div className='Modal-Input'>
              <label htmlFor="">Fecha de Alta</label>
              <input required type="date" value={bodyForm.alta} onChange={(e) => setBodyForm({...bodyForm, alta:e.target.value})}/>
            </div>
            <div className='Modal-Input'>
              <label htmlFor="">ID Empleado</label>
              <input required type="text" value={bodyForm.idempleado} onChange={(e) => setBodyForm({...bodyForm, idempleado:e.target.value})}/>
            </div>
            <div>
              <button>Guardar</button>
            </div>
          </div>
        </form>
      </Modal>}
      {modalOpenEdit && <Modal close={closeModal}> 
        <form className='Modal-form-input' onSubmit={e => submitFormEdit(e)}>
          <div className='Modal-form-input-img'>
            <img src={preview} alt="" onClick={() => {document.getElementById('fileNew').click()}} />
            <input className='Modal-form-input-file' accept="image/png, image/jpeg" id='fileNew' type="file" onChange={(e) => handleImage(e)} />
          </div>
          <div className='Modal-form-inputs'>
            <div className='Modal-Input'>
              <label htmlFor="">Nombre</label>
              <input required type="text" value={bodyFormEdit.nombre} onChange={(e) => setBodyFormEdit({...bodyFormEdit, nombre:e.target.value})} />
            </div>
            <div className='Modal-Input'>
              <label htmlFor="">Apellido</label>
              <input required type="text" value={bodyFormEdit.apellido} onChange={(e) => setBodyFormEdit({...bodyFormEdit, apellido:e.target.value})}/>
            </div>
            <div className='Modal-Input'>
              <label htmlFor="">Fecha de Alta</label>
              <input disabled type="date" value={moment(bodyFormEdit.alta).format('YYYY-MM-DD')} onChange={(e) => setBodyFormEdit({...bodyFormEdit, alta:e.target.value})}/>
            </div>
            <div className='Modal-Input'>
              <label htmlFor="">ID Empleado</label>
              <input required type="text" value={bodyFormEdit.idempleado} onChange={(e) => setBodyFormEdit({...bodyFormEdit, idempleado:e.target.value})}/>
            </div>
            <div className='Modal-Input'>
              <label htmlFor="">Estatus</label>
              <select onChange={(e) => setBodyFormEdit({...bodyFormEdit, estatus: e.target.value}) }>
                <option value={ bodyFormEdit.estatus === 'true' ? 'true' : 'false'} selected disabled hidden>{ bodyFormEdit.estatus === 'true' ? 'Habilitado' : 'Inhabilitado' }</option>
                <option value='true' >Habilitado</option>
                <option value='false'>Inhabilitado</option>
              </select>
            </div>
            <div className='button-guardarEditar'>
              <button>Guardar</button>
            </div>
            <div>
              <div className='button-formularios' onClick={() => exportAsImage("CodigoQR")}>
                  <span>imprimir QR</span>
              </div>
            </div>
            <div>
              <div className='button-formularios' onClick={() => deleteUser()}>
                <span>Eliminar</span>
              </div>
            </div>
            <div>
              <DatePicker
                selectsRange={true}
                startDate={startDateUser}
                endDate={endDateUser}
                placeholderText="Selecciona una fecha"
                onChange={(update) => {
                  const fechInicio = update[0]  ? moment(update[0]).format('YYYY-MM-DD') : null;
                  const fechFin = update[1] ? moment(update[1]).format('YYYY-MM-DD') : null;    
                  const objectFechas = { fechInicio, fechFin }
                  setDateReporteRange(objectFechas);
                  setDateRange(update);
                }}
                withPortal
              />
            </div>
            <div>
              <div className='button-formularios' onClick={() => exportReportUser()}>
                <span>Generar Reporte</span>
              </div>
            </div>
          </div>
          <div className='codigoQR' id='codigoQR' ref={ exportRef }>
            <div className='codigoQR-text'>
              <div className='codigoQR-text-logo'>
                <img src={ logo } alt="" />
              </div>
              <div className='codigoQR-text-title'>DID YOU LOVE OUR SERVICE?</div>
              <div className='codigoQR-text-body'>
                <span>Please leave us a review</span>
                <span>It will take 10 seconds and have a big impact on our business</span>
              </div>
              <div className='codigoQR-text-imagen'>
                <img src={ icon } alt="" />
              </div>
            </div>
            <div className='sectionQR'>
              <div>
                <div className='qrImagen'>
                  <QRCodeSVG value={srcQR} />
                </div>
                <div className='textQR'>
                  Scan Me
                </div>
                <div className='nEmpleado'>
                  { bodyFormEdit.idempleado ? bodyFormEdit.idempleado : ''}
                </div>
              </div>
              <div className='arrowQR'>
                <i className="fa-solid fa-share-all"></i>
              </div>
            </div>
          </div>
        </form>
      </Modal>}
      {modalRepote && <Modal close={setModalReporte}>
        <div className="modalReporte">
          <span>Selecciona un rango de fechas</span>
          <DatePicker
          selected={startDate}
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          inline
          />
          <button onClick={() => exportReport()} disabled={ endDate !== null ? false : true } className='button-reporte'>Descargar reporte</button>
        </div>
      </Modal>}
    </div>
  );
};

export default Conductores;