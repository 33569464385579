import React, { useState, useEffect } from 'react';
import querys from '../services/index'
import Modal from '../components/modal'

const Usuarios = () => {
  const [modalOpen, setmodalOpen] = useState(false)
  const [modalOpenEdit, setmodalOpenEdit] = useState(false)
  const [totalPag, setTotalPag] = useState(0)
  const [data, setData] = useState([])
  const [firstElement, setFirstElement] = useState(0)
  const [lastElement, setLastElement] = useState(4)
  const [pag, setPag] = useState(1)
  const [bodyForm, setBodyForm] = useState({})
  const [bodyFormEdit, setBodyFormEdit] = useState({})
  

  useEffect(() => {
    getUsers(null, null, 1)
  }, [])


  const getUsers = (form, body, pag) => {
    querys.getUserPag(form, body, pag)
    .then(response => {
      setData(response.data)
      setTotalPag(response.npage)
    })
    .catch( error => {
      console.log(error)
    })
  }

  const nextPage = () => {
    if (pag < totalPag) {
      setPag(pag+1)
      setFirstElement(lastElement)
      setLastElement(lastElement+4)
    }
  }
  const prevPage = () => {
    setPag(pag-1)
    setFirstElement(firstElement-4)
    setLastElement(lastElement-4)
  }


  const submitForm = (e) => {
    e.preventDefault();
    const inputs =  document.querySelectorAll('newUser')
    querys.insertUser(bodyForm)
    .then(response => {
      setBodyForm({})
      inputs.forEach(input => input.value = '')
      setmodalOpen(false)
      getUsers(null, null, 1)
    })
    .catch(error => {
      console.log(error)
    })
  }
  
  const submitFormEdit = (e) => {
    e.preventDefault();
    const inputs =  document.querySelectorAll('newUser')
    querys.updatetUser(bodyFormEdit)
    .then(response => {
      setBodyForm({})
      inputs.forEach(input => input.value = '')
      setmodalOpenEdit(false)
      getUsers(null, null, 1)
    })
    .catch(error => {
      console.log(error)
    })
  }

  const handleEdit = (item) => {
    setBodyFormEdit(item)
    setmodalOpenEdit(true)
  }

  return (
    <div className='review'>
      <div className='review-buttons'>
        <div className="review-button" onClick={() => setmodalOpen(true)}>
          <div className="review-buttonTitle" ><span>Agregar Usuarios</span></div>
        </div>
      </div>
      <div className='review-bodyDrivers'>
        <div className="review-bodyTableHead">
          <div>Nombre</div>
          <div>Apellido</div>
          <div>Email</div>
        </div>
        <div className="review-bodyTable">
        {data.length > 0 && data.map(item => {
          return(
            <div className='review-bodyTableItem' onClick={() => handleEdit(item)}>
              <div>{item.nombre}</div>
              <div>{item.apellido}</div>
              <div>{item.email}</div>
            </div>
          )
        })}
        </div>
      </div>
      <div className='pag'>
        <div>
          { pag !== 1 && <button onClick={() =>prevPage()}><i className="fa-solid fa-arrow-left"></i></button> }
          Pag.{pag} 
          { pag < totalPag && <button onClick={() =>nextPage()}><i className="fa-solid fa-arrow-right"></i></button> }
        </div>
      </div>
      {modalOpen && <Modal close={setmodalOpen}> 
        <form className='Modal-form-input' onSubmit={e => submitForm(e)}>
          <div className='Modal-form-inputs'>
            <div className='Modal-Input'>
              <label htmlFor="">Nombre</label>
              <input required type="text" value={bodyForm.nombre} onChange={(e) => setBodyForm({...bodyForm, nombre:e.target.value})} className='newUser' />
            </div>
            <div className='Modal-Input'>
              <label htmlFor="">Apellido</label>
              <input required type="text" value={bodyForm.apellido} onChange={(e) => setBodyForm({...bodyForm, apellido:e.target.value})} className='newUser' />
            </div>
            <div className='Modal-auto'>
              <label htmlFor="">Email</label>
              <input required type="text" value={bodyForm.email} onChange={(e) => setBodyForm({...bodyForm, email:e.target.value})} className='newUser' />
            </div>
            <div className='Modal-Input'>
              <label htmlFor="">Password</label>
              <input required type="text" value={bodyForm.password} onChange={(e) => setBodyForm({...bodyForm, password:e.target.value})} className='newUser' />
            </div>
            <div>
              <button>Guardar</button>
            </div>
          </div>
        </form>
      </Modal> }

      {modalOpenEdit && <Modal close={setmodalOpenEdit}> 
        <form className='Modal-form-input' onSubmit={e => submitFormEdit(e)}>
          <div className='Modal-form-inputs'>
            <div className='Modal-Input'>
              <label htmlFor="">Nombre</label>
              <input required type="text" value={bodyFormEdit.nombre} onChange={(e) => setBodyFormEdit({...bodyFormEdit, nombre:e.target.value})} className='newUser' />
            </div>
            <div className='Modal-Input'>
              <label htmlFor="">Apellido</label>
              <input required type="text" value={bodyFormEdit.apellido} onChange={(e) => setBodyFormEdit({...bodyFormEdit, apellido:e.target.value})} className='newUser' />
            </div>
            <div className='Modal-auto'>
              <label htmlFor="">Email</label>
              <input required type="text" value={bodyFormEdit.email} onChange={(e) => setBodyFormEdit({...bodyFormEdit, email:e.target.value})} className='newUser' />
            </div>
            <div className='Modal-Input'>
              <label htmlFor="">Password</label>
              <input required type="text" value={bodyFormEdit.password} onChange={(e) => setBodyFormEdit({...bodyFormEdit, password:e.target.value})} className='newUser' />
            </div>
            <div>
              <button>Guardar</button>
            </div>
          </div>
        </form>
      </Modal> }
    </div>
  );
};

export default Usuarios;