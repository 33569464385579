import React, { useEffect, useState, useRef  } from 'react';
import logo from '../assetns/logo.jpg'
import { Link, useParams } from 'react-router-dom';
import { Navigate } from "react-router-dom"
import languageEnglish from '../language-en'
import languageSpanish from '../language-es'
import querys from '../services/index'
import moment from 'moment'
import { useDispatch } from 'react-redux';
import axios from 'axios'
import eu from '../assetns/eu.png'
import mx from '../assetns/mx.png'
import logoPNG from '../assetns/logo.png'
import gift from '../assetns/gif-finca-18.gif'

const WrapperForm = () => {
  const { lang } = useParams();
  const isValidLocale = lang === 'es-mx' || lang === 'en';
  return isValidLocale ? Form() : <Navigate replace to="/en/001" />
}

const Form = () => {
  const { lang, idConduct } = useParams();
  const [language, setLenguage] = useState([])
  const [countrySearch, setCountrySearch] = useState('')
  const [countries, setCountries] = useState([]);
  const [resultCountries, setResultCountries] = useState([])
  const [dataForm, setDataForm] = useState({})
  const [loading, setloading] = useState(false)
  const [message, setMessage] = useState(false)
  const [porcent, setPorcent] = useState(0)
  const toolktip = useRef();
  const refContainer = useRef(null)
  const dispatch = useDispatch();
  const [elements, setElements] = useState(
    [
      {
        url: gift,
        text: 'thank you for traveling with us in an honor'
      },
      {
        url: gift,
        text: 'If you liked our service, you would help us by adding a review on tripadvisor'
      },
    ]
  )

  useEffect(() => {
    if (lang === 'en') {
      setLenguage(languageEnglish)
    } else {
      setLenguage(languageSpanish)
    }
    axios.get(`https://restcountries.com/v3.1/all`)
    .then((response) => {
      setCountries(response.data)
    })
  },[lang])

  const TraslateCopy = (CopyID) => {
    const text = language.find(copy => copy.CopyId === CopyID);
    return text?.text
  }

  const handleSearch = (e) => {
    setCountrySearch(e.target.value)
    filterSearch(e.target.value)
  }

  const filterSearch = (param) => {
    const resultadoSearch = countries.filter(country => {
      if (country.name.common.toString().toLowerCase().includes(param.toLowerCase())) {
        return country
      }
    });
    setResultCountries(param !== '' ? resultadoSearch : [])
  }

  const handleDataForm = (value, name) => {
    setDataForm({...dataForm, [name]: value})
  }

  const selectCountry = (country) => {
    setCountrySearch(country)
    setDataForm({...dataForm, country: country})
    setResultCountries([])
  }

  const handleSelect = () => {
    const style = refContainer.current.style.display
    refContainer.current.style.display = style === 'flex' ? 'none' : 'flex'
  }

  const handleButton = (e) => {
    e.preventDefault()
    if (!dataForm.rate) {
      toolktip.current.style.display = 'block'
      setTimeout(() => {
        toolktip.current.style.display = 'none'
      }, 3000);
    } else {
      setloading(true)
      let i = 1;
      const myInterval = setInterval(() => {
        setPorcent( i++ * 10)
      }, 500);
      const sliderInterval = setInterval(() => {
        const slider = document.querySelector('.containerLoading-slide');
        const firsElement = document.querySelectorAll('.containerLoading-container')[0];
        const firstElementWidth = firsElement.clientWidth + 19;
        slider.scrollLeft += firstElementWidth;
      },3333)
      const bodyQuery = {...dataForm, fecha: moment().format('YYYY-MM-DD') + ' ' + moment().format('LT') ,idcar: idConduct}
      querys.insertReview(bodyQuery, dispatch)
      .then(response => {
        const inputs =  document.querySelectorAll('input')
        const textarea =  document.querySelectorAll('textarea')
        const inputRadio = document.querySelectorAll('.radio')
        setTimeout(() => {
          clearInterval(sliderInterval);
          clearInterval(myInterval);
          if (dataForm.rate >= 3) {
            window.location.assign("https://www.tripadvisor.com/UserReviewEdit-g150793-d8421791-TPV_Airport_Transportation_Puerto_Vallarta-Puerto_Vallarta.html")
          } else {
            clearInterval(sliderInterval);
            clearInterval(myInterval);
            setMessage(true)
            setloading(false)
          }
          inputRadio.forEach(input => input.checked = false)
          inputs.forEach(input => input.value = '')
          textarea.forEach(input => input.value = '')
          setCountrySearch('')
          setDataForm({})
        }, 10000);
      })
      .catch(error => {
        console.log(error)
        setloading(false)
      })
    }

  }
  return (
    <div className='form'>
      <div className="form-wrapper">
        <div className="form-title">
          <div className='languageContainer'>
            <div className='languageSelect' onClick={() => handleSelect()}>{ lang === 'en' ? <img src={eu} alt="" /> : <img src={mx} alt="" /> } <i className="fa-sharp fa-solid fa-circle-arrow-down"></i> </div>
            <div ref={refContainer} className='languageContainerOption'>
              <Link to={`/es-mx/${idConduct}`} onClick={() => handleSelect()}>
                <img src={mx} alt="" />
                <span>Español</span>
              </Link>
              <Link to={`/en/${idConduct}`} onClick={() => handleSelect()}>
                <img src={eu} alt="" />
                <span>English</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="form-header">
          <div className="form-logo"><img src={logo} alt="" /></div>
        </div>
        <div className="form-question">
          <form action="" className='form-form' onSubmit={(e) => handleButton(e)}>
            <div className="form-input">
              <label htmlFor="">{TraslateCopy("FORM_YOUR_NAME")}</label>
              <input required value={ dataForm.name } placeholder={TraslateCopy("FORM_YOUR_PLACEHOLDER_NAME")} type="text" onChange={(e) => handleDataForm(e.target.value,'name')}/>
            </div>
            <div className="form-input">
              <label htmlFor="">{TraslateCopy("FORM_YOUR_COUNTRY")}</label>
              <input required type="text" placeholder={TraslateCopy("FORM_YOUR_PLACEHOLDER_COUNTRY")} value={countrySearch} onChange={(e) => handleSearch(e)}></input>
              {resultCountries.length > 0 && 
                <div className='searchResult'>
                  <ul>
                    {
                      resultCountries.map(country => 
                      <li key={ country.name.common } onClick={() => selectCountry(country.name.common)} >{ country.name.common }</li>
                      )
                    }
                  </ul>
                </div>
              }
            </div>
            <div className="form-input">
              <label htmlFor="">{TraslateCopy("FORM_YOUR_EMAIL")}</label>
              <input required type="email" placeholder={TraslateCopy("FORM_YOUR_PLACEHOLDER_EMAIL")} value={ dataForm.email } onChange={(e) => handleDataForm(e.target.value,'email')}/>
            </div>
            <div className="form-input">
              <span ref={toolktip} className="tooltiptext"> <i className="fa-regular fa-circle-exclamation"></i> {TraslateCopy("FORM_YOUR_TOOLTIP")}</span>
              <label htmlFor="">{TraslateCopy("FORM_YOUR_EXPERIENCE")}</label>
              <div className="startWidget">
                <input type="radio" className='radio' name="rate" id="rate-5" onChange={() => handleDataForm(5, 'rate')}/>
                <label htmlFor="rate-5" className='fas fa-star'></label>
                <input type="radio" className='radio' name="rate" id="rate-4" onChange={() => handleDataForm(4, 'rate')}/>
                <label htmlFor="rate-4" className='fas fa-star'></label>
                <input type="radio" className='radio' name="rate" id="rate-3" onChange={() => handleDataForm(3, 'rate')}/>
                <label htmlFor="rate-3" className='fas fa-star'></label>
                <input type="radio" className='radio' name="rate" id="rate-2" onChange={() => handleDataForm(2, 'rate')}/>
                <label htmlFor="rate-2" className='fas fa-star'></label>
                <input type="radio" className='radio' name="rate" id="rate-1" onChange={() => handleDataForm(1, 'rate')}/>
                <label htmlFor="rate-1" className='fas fa-star'></label>
              </div>
            </div>
            <div className="form-input">
              <label htmlFor="">{TraslateCopy("FORM_YOUR_LEAVE")}</label>
              <textarea required placeholder={TraslateCopy("FORM_YOUR_PLACEHOLDER_REVIEW")} type="text" value={ dataForm.review } onChange={(e) => handleDataForm(e.target.value,'review')}/>
            </div>
            <button disabled={loading}>{TraslateCopy("FORM_YOUR_BUTTON")}</button>
          </form>
          { message && <div className="containerLoading">
            <div className="containerLoading-containerTanks">
              <div><i className="fa-solid fa-check-to-slot"></i></div>
              <div><img src={logo} alt="" /></div>
              <div>{TraslateCopy("FORM_YOUR_PLACEHOLDER_TANKS")}</div>
            </div>
          </div> }
          { loading && <div className="containerLoading">
            <div className='containerLoading-slide'>
              {elements.length && elements.map(element => {
                return (
                  <div className='containerLoading-container'>
                    <div className='containerLoading-imagen' style={{backgroundImage:`URL(${ element.url })` }}></div>
                    <div className='containerLoading-text'>{ element.text }</div>
                  </div>
                )
              })}
            </div>
            <div className='loadingBar'>
              <div style={{width:`${porcent}%`}}></div>
            </div>
          </div> }
        </div>
      </div>
    </div>
  );
};



export default WrapperForm;