import React, { useEffect } from 'react';

const Stars = (rate) => {
  
  return (
    <div className="startComponent">
      <label htmlFor="rate-5" className={`fas fa-star ${rate.rate >= 5 && 'activeStar'}`}></label>
      <label htmlFor="rate-4" className={`fas fa-star ${rate.rate >= 4 && 'activeStar'}`}></label>
      <label htmlFor="rate-3" className={`fas fa-star ${rate.rate >= 3 && 'activeStar'}`}></label>
      <label htmlFor="rate-2" className={`fas fa-star ${rate.rate >= 2 && 'activeStar'}`}></label>
      <label htmlFor="rate-1" className={`fas fa-star ${rate.rate >= 1 && 'activeStar'}`}></label>
    </div>
  );
};

export default Stars;