const traslatesCopys = [
  {
    "CopyId": "FORM_YOUR_NAME",
    "text": "What's your name?"
  },
  {
    "CopyId": "FORM_YOUR_COUNTRY",
    "text": "Country of residence"
  },
  {
    "CopyId": "FORM_YOUR_EMAIL",
    "text": "Email"
  },
  {
    "CopyId": "FORM_YOUR_EXPERIENCE",
    "text": "Rate your experience"
  },
  {
    "CopyId": "FORM_YOUR_LEAVE",
    "text": "Leave a review"
  },
  {
    "CopyId": "FORM_YOUR_TITLE",
    "text": "Thanks for traveling with us"
  },
  {
    "CopyId": "FORM_YOUR_BUTTON",
    "text": "Post Review"
  },
  {
    "CopyId": "FORM_YOUR_TOOLTIP",
    "text": "Rate our service"
  },
  {
    "CopyId": "FORM_YOUR_PLACEHOLDER_NAME",
    "text": "Who travels with us?"
  },
  {
    "CopyId": "FORM_YOUR_PLACEHOLDER_COUNTRY",
    "text": "Search your country"
  },
  {
    "CopyId": "FORM_YOUR_PLACEHOLDER_EMAIL",
    "text": "Whast's is your email"
  },
  {
    "CopyId": "FORM_YOUR_PLACEHOLDER_REVIEW",
    "text": "Tell us about your experience with our service"
  },
  {
    "CopyId": "FORM_YOUR_PLACEHOLDER_TANKS",
    "text": "Thank you for your review to improve our service."
  },
]


export default traslatesCopys;