import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/main.scss';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Navigate } from "react-router-dom";
import { AuthContextProvider } from './contexts/authContext';
import Form from './routes/form'
import Dashboard from './components/dashboard'
import Reviews from './routes/reviews'
import Drivers from './routes/conductores'
import Autos from './routes/autos'
import {store} from './redux/store';
import { Provider } from 'react-redux';
import Login from './routes/login'
import Usuarios  from './routes/usuarios'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate replace to="/en/001" />,
  },
  {
    path: "/:lang",
    element: <Navigate replace to="/en/001" />,
  },
  {
    path: "/:idConduct",
    element: <Navigate replace to="/en/001" />,
  },
  {
    path: "/:lang/:idConduct",
    element: <Form/>,
  },
  {
    path:'login',
    element: <Login/>
  },
  {
    path: "/dashboard",
    element: <Dashboard/>,
    children: [
      {
        index:true,
        element: <Reviews/>
      },
      {
        path:'conductores',
        element: <Drivers/ >
      },
      {
        path:'autos',
        element: <Autos/>
      },
      {
        path:'usuarios',
        element: <Usuarios/>
      },
    ]
  }
]);
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <AuthContextProvider>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </AuthContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
