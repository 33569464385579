import React from 'react';
import Stars from '../components/stars'
import moment from 'moment'
import 'moment/locale/es';

const modalReviews = ({elemet}) => {

  const numberForLeft = (number) => {
    const string = number.toString()
    const totalCeros = 5 - string.length

    return string.padStart(4, '0')
  }

  return (
    <div className='modalReview'>
    <div className='ModalReview-title'>
      <div className='ModalReview-Header'>
        <span className='ModalReview-name'>{elemet.name}</span> 
        <Stars rate={elemet.rate} />
      </div>
      <div className='ModalReview-subtitle'>
        <span>{elemet.email}</span>
        <span>{elemet.country} | { moment(elemet.fecha).format('lll') }</span>
      </div>
    </div>
    <div className='review-text'><p>{elemet.review}</p></div>
    <div className='review-info'>
      <div><i className="fa-regular fa-cars"></i>{numberForLeft(elemet.idcar)}</div>
      <div onClick={ () => document.querySelector('.driver-Info').style.display = 'grid'}><i className="fa-regular fa-id-card"></i> {elemet.nombre}</div>
    </div>
    <div className='driver-Info'>
      <div className='driver-info-div'>
        <label htmlFor="">Nombre</label>
        <label htmlFor="">{ elemet.nombre + ' ' + elemet.apellido }</label>
      </div>
      <div className='driver-info-div'>
        <label htmlFor="">Calificación promedio</label>
        <Stars rate={ elemet.promedio } />
      </div>
      <div className='driver-info-div'>
        <label htmlFor="">Fecha de alta</label>
        <label htmlFor="">{ moment(elemet.alta).format('YYYY-MM-DD') }</label>
      </div>
      <div className='driver-info-div'>
        <label htmlFor="">ID de empleado</label>
        <label htmlFor="">{ elemet.idempleado }</label>
      </div>
    </div>
  </div>
  );
};

export default modalReviews;